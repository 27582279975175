<template>
  <div class="container">
    <div class="header">
      <h1>Spiritual Gift Test</h1>
    </div>
    <div class="row">
      <div class="col-6 col-s-9">
        {{ questions[current] }}
      </div>
      <div class="col-3 col-s-12 menu">
        <ul>
          <li><input type="radio" id="really" value="1" v-model="answers[current][1]"><label for="really">Really Enjoy</label></li>
          <li><input type="radio" id="usually" value="2" v-model="answers[current][1]"><label for="usually">Usually Enjoy</label></li>
          <li><input type="radio" id="seldom" value="3" v-model="answers[current][1]"><label for="seldom">Seldom Enjoy</label></li>
          <li><input type="radio" id="never" value="4" v-model="answers[current][1]"><label for="never">Never Enjoy</label></li>
        </ul>
      </div>
    </div>
    <div class="footer">
      <h3>Question {{ current + 1 }} of {{ questions.length }}</h3>
      <div><button class="btn" v-on:click="goPrevious">Previous</button><span><button class="btn" v-on:click="goNext">Next</button></span></div>
      <div><button class="btn" v-on:click="showGift">Show Results</button></div>
    </div>
    <div v-if="showResults" class="results">
      <div class="row">
        <div class="header" style="background-color: red"><h1>Your Gifts that Lift</h1></div>
        <div class="col-6 col-s-9" style="background-color: yellow">
          <h3>Primary Gift:</h3>
          <p>{{ primaryGift }}</p>
        </div>
        <div class="col-6 col-s-9" style="background-color: orange">
          <h3>Secondary Gift:</h3>
          <p>{{ secondaryGift }}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
export default {
  name: "Test",
  components: {},
  data() {
    return {
      current: 0,
      showResults: false,
      primaryGift: "",
      secondaryGift: "",
      questions: [
        "I enjoy sensing the direction God wants His people to move and then sharing it w/others.",
        "I enjoy pitching in on service projects in the church.",
        "I enjoy organizing my thinking in order to systematically present a Bible lesson to others.",
        "I enjoy it when people come to me with their personal problems for counsel.",
        "I enjoy giving to those in serious financial need.",
        "I enjoy earning people’s respect by successfully leading projects.",
        "I enjoy opportunities to do what I can to help those who are in distress.",
        "I enjoy speaking up for what is biblically right even when others oppose my principles.",
        "I enjoy volunteering to help with tasks that need to be done.",
        "I enjoy diligent study so as to accurately teach the Word to others.",
        "I enjoy encouraging those who are discouraged and down-hearted.",
        "I enjoy giving my extra money to the work of the Lord as often as possible.",
        "I enjoy setting goals and seeing the direction a group of people should take.",
        "I enjoy visiting the sick and shut-in.",
        "I enjoy explaining Biblical truth from the Word so people know what God expects of them.",
        "I enjoy working at a task regardless of how simple or trivial it may seem.",
        "I enjoy hearing how people have learned or grown when I teach them.",
        "I enjoy challenging others to reach their potential in Christ.",
        "I enjoy sacrificial giving. (Doing without so that others needs are met first.)",
        "I enjoy guiding and motivating people to join in the achievement of my goals.",
        "I enjoy looking out for those who are neglected and alienated.",
        "I enjoy opportunities to remind people of God's judgment on sin.",
        "I enjoy being in charge of seeing a job through to completion.",
        "I enjoy studying Scripture for myself and so that I can share my findings with others.",
        "I enjoy making myself available to talk with others.",
        "I enjoy giving anonymously to those in need.",
        "I enjoy assuming the leadership of a group, especially if there is no other leader.",
        "I enjoy ministering to those who are embarrassed or humiliated. I seek to comfort them.",
        "I enjoy speaking boldly and with conviction what I believe God wants people to know.",
        "I enjoy the opportunity of people counting on me to get something important done.",
        "I enjoy making the Bible clear and relevant for others.",
        "I enjoy encouraging others in times of urgent need.",
        "I enjoy lowering my standard of living, if necessary, in order to help others out.",
        "I enjoy setting goals and I usually do.",
        "I enjoy spending time with those who are lonely and hurting to cheer them up.",
      ],
      answers: [
        ["questionOne", ""],
        ["questionTwo", ""],
        ["questionThree", ""],
        ["questionFour", ""],
        ["questionFive", ""],
        ["questionSix", ""],
        ["questionSeven", ""],
        ["questionEight", ""],
        ["questionNine", ""],
        ["questionTen", ""],
        ["questionEleven", ""],
        ["questionTwelve", ""],
        ["questionThirteen", ""],
        ["questionFourteen", ""],
        ["questionFifteen", ""],
        ["questionSixteen", ""],
        ["questionSeventeen", ""],
        ["questionEightteen", ""],
        ["questionNineteen", ""],
        ["questionTwenty", ""],
        ["questionTwentyOne", ""],
        ["questionTwentyTwo", ""],
        ["questionTwentyThree", ""],
        ["questionTwentyFour", ""],
        ["questionTwentyFive", ""],
        ["questionTwentySix", ""],
        ["questionTwentySeven", ""],
        ["questionTwentyEight", ""],
        ["questionTwentyNine", ""],
        ["questionThirty", ""],
        ["questionThirtyOne", ""],
        ["questionThirtyTwo", ""],
        ["questionThirtyThree", ""],
        ["questionThirtyFour", ""],
        ["questionThirtyFive", ""]
      ],
      gifts: [
        {"gift": "Leader", "score": 0, "rank": 0},
        {"gift": "Serving", "score": 0, "rank": 0},
        {"gift": "Exhorting", "score": 0, "rank": 0},
        {"gift": "Mercy", "score": 0, "rank": 0},
        {"gift": "Prophesy", "score": 0, "rank": 0},
        {"gift": "Teaching", "score": 0, "rank": 0},
        {"gift": "Giving", "score": 0, "rank": 0},
      ]
    };
  },
  methods: {
    goNext() {
      if(this.answers[this.current][1] === "") {
        alert("Answer needed to continue.")
        return;
      }
      if(this.current < this.questions.length - 1) {
        this.current += 1;
      }
    },
    goPrevious() {
      if(this.current > 0) {
        this.current -= 1;
      }
    },
    showGift() {
      if(this.current < this.questions.length - 1) {
        alert("Please answer all the questions.")
        return;
      }
      let leader =
        Number(this.answers[5][1]) +
        Number(this.answers[12][1]) +
        Number(this.answers[19][1]) +
        Number(this.answers[26][1]) +
        Number(this.answers[33][1]);
      let serving =
        Number(this.answers[1][1]) +
        Number(this.answers[8][1]) +
        Number(this.answers[15][1]) +
        Number(this.answers[22][1]) +
        Number(this.answers[29][1]);
      let exhorting =
        Number(this.answers[3][1]) +
        Number(this.answers[10][1]) +
        Number(this.answers[17][1]) +
        Number(this.answers[24][1]) +
        Number(this.answers[31][1]);
      let mercy =
        Number(this.answers[6][1]) +
        Number(this.answers[13][1]) +
        Number(this.answers[20][1]) +
        Number(this.answers[17][1]) +
        Number(this.answers[34][1]);
      let prophesy =
        Number(this.answers[0][1]) +
        Number(this.answers[17][1]) +
        Number(this.answers[14][1]) +
        Number(this.answers[21][1]) +
        Number(this.answers[28][1]);
      let teaching =
        Number(this.answers[2][1]) +
        Number(this.answers[9][1]) +
        Number(this.answers[16][1]) +
        Number(this.answers[23][1]) +
        Number(this.answers[30][1]);
      let giving =
        Number(this.answers[4][1]) +
        Number(this.answers[11][1]) +
        Number(this.answers[18][1]) +
        Number(this.answers[25][1]) +
        Number(this.answers[32][1]);

      var giftArray = [];

      this.gifts[0].score = leader / 5;
      this.gifts[1].score = serving / 5;
      this.gifts[2].score = exhorting / 5;
      this.gifts[3].score = mercy / 5;
      this.gifts[4].score = prophesy / 5;
      this.gifts[5].score = teaching / 5;
      this.gifts[6].score = giving / 5;

      giftArray.push(this.gifts[0].score);
      giftArray.push(this.gifts[1].score);
      giftArray.push(this.gifts[2].score);
      giftArray.push(this.gifts[3].score);
      giftArray.push(this.gifts[4].score);
      giftArray.push(this.gifts[5].score);
      giftArray.push(this.gifts[6].score);

      function rankify(A, n) {
        // Rank Vector
        var R = [...Array(n)];

        // Sweep through all elements in A for each
        // element count the number of less than and
        // equal elements separately in r and s.
        for (var i = 0; i < n; i++) {
          var r = 1,
            s = 1;

          for (var j = 0; j < n; j++) {
            if (j != i && A[j] < A[i]) r += 1;

            if (j != i && A[j] == A[i]) s += 1;
          }

          // Use formula to obtain rank
          R[i] = parseFloat(r + parseFloat(s - 1) / parseFloat(2));
        }
        
        return R;
      }
      var n = giftArray.length;
      let rankArray = rankify(giftArray, n);
      this.gifts[0].rank = rankArray[0];
      this.gifts[1].rank = rankArray[1];
      this.gifts[2].rank = rankArray[2];
      this.gifts[3].rank = rankArray[3];
      this.gifts[4].rank = rankArray[4];
      this.gifts[5].rank = rankArray[5];
      this.gifts[6].rank = rankArray[6];
      let rankOrder = rankArray.sort(function(a, b){return a - b});
      let lowOne = rankOrder[0]
      let lowTwo = rankOrder[1]
      for (let value in this.gifts) {
        if(lowOne === this.gifts[value].rank) {
          this.primaryGift = this.gifts[value].gift
          this.gifts[value].rank = 99
        }
        if(lowTwo === this.gifts[value].rank) {
          this.secondaryGift = this.gifts[value].gift
          this.gifts[value].rank = 99
        }
      }
      this.showResults = true;
    },
  },
};
</script>
