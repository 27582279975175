<template>
  <div class="contaner">
    <div class="header">
      <h1>Welcome To The Spiritual Gift Test!</h1>
    </div>
    <div>
      <p>This test is designed to help you determine which gifts you may possess as primary and secondary motivational gifts of the Spirit.  Of course, no test could replace the illumination of the Spirit in realizing your true Spiritual gift.  However, I have placed multiple numbers of people on this test and, when it has been taken properly, I have been able to conclude their primary and secondary gifts with remarkable accuracy.</p>
      <p>The key to this test is that phrase “when taken properly.”  If you take this test and do not follow the suggestions listed below, you will most assuredly end up getting a false test score.  In doing so, you would misrepresent your gifts</p>
      <p>To misdirect someone in their spiritual gift could be quite harmful as you might imagine.  In an effort to “produce juice” in their Christian service, they could find themselves slotted improperly in the Lord’s work.  This would reduce their motivational juice rather than produce that much-needed adrenaline for Christian service.</p>

    </div>
    <div>
        <h3>How to take your test:</h3>
        <ul>
          <li>Look at each question and determine if it is something you really enjoy, usually enjoy, seldom enjoy, or never enjoy,</li>
          <li>DO NOT; I repeat, DO NOT mark it as something you enjoy if you do not.</li>
          <li>If it is something you have never done, but you think you would enjoy it, then answer it as if you have done it and measure your anticipated level of enjoyment in it.</li>
          <li>It's okay to choose that you do not like doing something.</li>
        </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home'
}
</script>

<style>

</style>